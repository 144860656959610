import React from "react"

const About = () =>{
    return(
        <div className=" p-8 items-center justify-center">
      <div className="text-center font-bold text-4xl text-black m-2">ABOUT US</div>
      <div className=" font-bold text-xl font-sans m-2 items-center text-center">
            We pride ourselves on our craveable menu, along with our standards on 
            <br/>food safety and product quality.
             We are covet to serve you<br/> best and see Smiles on Everyone's Face.
        </div>
        </div>
    )
}

export default About;