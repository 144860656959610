import React,{useEffect, useState} from "react"
import { Icon } from '@iconify/react';
import Pizza from '../assets/pizza.png'
import Burger from '../assets/burger.png'
import Momos from '../assets/momos.png'
import Logo from '../assets/spice-hut-logo-lines-2.svg'
import Sandwich from '../assets/sandwich.png'
import { useNavigate } from "react-router-dom";
import Roll from '../assets/roll.png'
import Chilli from '../assets/red-chilli.png'
import Pepper from '../assets/black-pepper.png'
import Peep from '../assets/menu-peep.svg'
import About from "../components/aboutus";
import Location from "../components/locations";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
    initial: direction => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
        // scale: 0.5,
      }
    },
    animate: {
      x: 0,
      opacity: 1,
      // scale: 1,
      // transition: 'ease-in',
      transition: {
        x: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      },
    },
    exit: direction => {
      return {
        x: direction > 0 ? -1000 : 1000,
        opacity: 0,
        // scale: 0.5,
        // transition: 'ease-in',
        transition: {
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        },
      }
    },
  }

  const images = [Pizza, Burger, Momos, Sandwich, Roll];

const Home = () =>{

    const [index, setIndex] = useState(0)
    const [direction, setDirection] = useState(0)
 
    const navigate = useNavigate(); 

    const showtwitter = () => {
      window.open("https://twitter.com/RealSpicehut?t=Mq1QNKrGFUWoCB7WJEsN5g&s=08");
    };

    const showfacebook = () => {
      window.open("https://www.facebook.com/profile.php?id=100086386977343");
    };

    const showinsta = () => {
      window.open("https://www.instagram.com/spicehutofficial?r=nametag");
    };

    function menu(e){
      navigate('/menus')
    }

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //         nextStep()
    //       }, 3000);
    //       return() => clearInterval(interval)
    //     }, [index,direction])
    
    function nextStep() {
        setDirection(1)
        if (index === images.length - 1) {
          setIndex(0)
          return
        }
        setIndex(index + 1)
      }
    
      function prevStep() {
        setDirection(-1)
        if (index === 0) {
          setIndex(images.length - 1)
          return
        }
        setIndex(index - 1)
      }
   

    return(
        <motion.div initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}>
     
        <div className="flex flex-col min-h-screen space-y-20">
            {/* slideshow */}
            <div className="flex mt-20 w-full ">
          <div className="flex items-center justify-center 2xl:bg-logo xl:bg-logo md:bg-logo bg-no-repeat bg-center bg-fixed 2xl:bg-[length:600px] xl:bg-[length:600px] md:bg-[length:600px] sm:bg-[length:300px] w-full  2xl:h-[450px] xl:h-[450px] md:h-[450px]  md:mt-8 sm:mt-8">
          <div className="flex flex-row w-full justify-center items-center md:space-x-30">
          <button onClick={prevStep} className="flex justify-center items-center rounded-full h-full p-4 m-2 transition ease-in-out delay-50 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
                <Icon icon="line-md:chevron-left" color="orange" className="text-xl"/>
            </button>
         <div className="flex justify-center md:w-[500px] w-[500px]">
         <motion.img
            variants={variants}
            animate='animate'
            initial='initial'
            exit='exit'
            src={images[index]}
            alt='slides'
            className='slides justify-center items-center'
            key={images[index]}
            custom={direction}
          />
         </div>
         
    
            <button onClick={nextStep} className="flex items-center rounded-full h-full p-4 m-2 transition ease-in-out delay-50 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
            <Icon icon="line-md:chevron-right" color="orange" className="text-xl"/>
            </button>
          </div>
       
            </div>

            <div className="flex flex-col 2xl:mt-20 xl:mt-20 md:mt-20 contacts">
            <button onClick={showtwitter} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
            <Icon icon="line-md:twitter" color="orange" className="text-xl"/>
            </button>
            <button onClick={showfacebook} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
                <Icon icon="line-md:facebook" color="orange" className="text-xl"/>
            </button>
            <button onClick={showinsta} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
            <Icon icon="line-md:instagram" color="orange" className="text-xl"/>
            </button>
          
          </div>
            </div>
         
            {/* peep the menu */}
            <div className="menu flex md:flex-row  justify-center items-center ">
                  <div className="flex flex-col justify-center items-center 2xl:mr-12 p-16 w-auto">
                  <div className="font-bold text-4xl text-center text-black m-2">MENU</div>
                  <div className=" font-bold text-xl font-sans m-2 mb-10 text-center">
                    Everything we serve is house-made and<br/> prepared by a staff that cares.
                     Prepared by fresh <br/>stock of spices and vegetables.
                   </div>
                 <button onClick={menu} className="flex justify-center border font-bold border-black rounded-md p-2 h-auto w-48 transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-white duration-200 ">
                    PEEP THE MENU
                 </button>
                 
                  </div>

                  <img src={Peep} className="peep flex justify-center items-center 2xl:ml-52 p-2" alt="noodles"/>

           
                 
            </div>
            
            {/* end menu */}

            {/* start aboutus */}

            <div >
              <About/>
            </div>  

            {/* end about */}
            {/* start location */}
            <div >
              <Location/>
            </div>
            {/* end location */}
        </div>
      
        </motion.div>
    )
}

export default Home;