import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from '@iconify/react';
const Footer = () => {

    const handleClick = () => {
        window.open("https://devzodiac.in");
      };

    const showtwitter = () => {
        window.open("https://twitter.com/RealSpicehut?t=Mq1QNKrGFUWoCB7WJEsN5g&s=08");
      };
  
      const showfacebook = () => {
        window.open("https://www.facebook.com/profile.php?id=100086386977343");
      };
  
      const showinsta = () => {
        window.open("https://www.instagram.com/spicehutofficial?r=nametag");
      };
    return (
        <div className="w-full bg-black p-2">
      
        <div className="flex flex-row flex-wrap items-center justify-center ">

        <div className="flex flex-row mr-0 md:mr-auto sm:mr-0 md:justify-center sm:justify-center p-4">

            <button onClick={showtwitter} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-orange-500 hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
                    <Icon icon="line-md:twitter" color="black" className="text-xl"/>
                </button>
                <button onClick={showfacebook} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-orange-500 hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
                    <Icon icon="line-md:facebook" color="black" className="text-xl"/>
                </button>
                <button onClick={showinsta} className="flex justify-center items-center rounded-full p-4 m-2 transition ease-in-out delay-50 bg-orange-500 hover:-translate-y-1 hover:scale-110 hover:bg-orange-800 duration-200">
                    <Icon icon="line-md:instagram" color="black" className="text-xl"/>
                </button>

            </div>

           {/* <div className="flex flex-col  items-center">

               
                    <div className="flex flex-row justify-center text-xs space-x-3">
                      
                        <button className="flex items-center  text-white p-2  transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200">
                            <h1 >CONTACT  US</h1>
                        </button>              
        
                        <button className="flex items-center text-white p-2 transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200">
                            <h1 >CARRERS</h1>
                        </button>
            
                        <button className="flex items-center  text-white p-2  transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200">
                            <h1 >BRANCHES</h1>
                        </button>
                       
                       
                    </div>                 */}
              

                <div className="text-orange-500 text-xs font-extrabold ">
                <button onClick={handleClick} className="p-2  hover:text-white  delay-50 hover:-translate-y-1  duration-200">
                    <h1>DEVELOPED BY DEVZODIAC</h1>
                    </button>
                </div>

            {/* </div> */}

            <div className="flex flex-col p-4 pr-10 space-y-3 ml-0 md:ml-auto sm:ml-0 md:justify-center sm:justify-center">

                <div className="flex flex-row space-x-2 md:justify-start sm:justify-center justify-center items-center">
                    <Icon icon="mdi:phone" className="text-xl text-orange-500"/>
                    <h1 className="text-white">+91 904-463-6780</h1>
                </div>

                <div className="flex flex-row space-x-2  md:justify-start sm:justify-center justify-center items-center">
                    <Icon icon="mdi:email" className="text-xl text-orange-500"/>
                    <h1 className="text-white">tryspicyhut@gmail.com</h1>
                </div>
                
                
            </div>
       
        </div>
         
      </div>
    );
};
export default Footer;