import React from "react"
import LocationImage from '../assets/location-spice-hut.png'
const Location = () =>{
    
    const showInMapClicked = () => {
        window.open("https://www.google.com/maps/place/26%C2%B049'32.4%22N+80%C2%B052'29.5%22E");
      };
    return(
        
        <div className="flex 2xl:flex-row flex-col justify-center items-center mb-20 ">
             <div className=" p-8 items-center justify-center 2xl:mt-32 2xl:mb-32 w-auto">
      <div className="text-center font-bold text-4xl text-black m-2">LOCATE US</div>
      <div className=" font-bold text-xl font-sans m-2 items-center text-center">
      Khinni khera, devpur, near para police thana,
      <br/> opp. Gaurav tent house, rajajipuram, 
      <br/> Lucknow, Uttar Pradesh, 226017
        </div>
        </div>
        <img src={LocationImage}  width={600}
        height={600} alt="location"
        onClick={showInMapClicked} className="border-2 2xl:ml-32 m-4 border-orange-500 shadow-md rounded-lg transition ease-in-out delay-50 hover:-translate-y-1 2xl:hover:scale-110 xl:hover:scale-110 md:hover:scale-110  duration-200 cursor-pointer"/>
        </div>
    )
}

export default Location;