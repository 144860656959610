import React from "react";
import { NavLink } from "react-router-dom";
import LogoWhite from '../assets/spice-hut-logo-2-lines-white.svg'
const Navbar = () => {
  return (
    <div className="flex flex-row justify-center w-full relative bg-black p-8">

      <div className="flex flex-col flex-wrap space-y-4 items-center ">
        <NavLink to="/">
          <div className="flex flex-row group pl-2 pr-2 items-center cursor-pointer space-x-4 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-200">
            <h1 className="hut group-hover:text-orange-500 text-white">
              SPICE
            </h1>
            <h1 className="hut group-hover:text-white text-orange-500 ">
              HUT
            </h1>
          </div>
        </NavLink>

        {/* start buttons */}

        {/* <div className="flex flex-row space-x-40">
          <div className="flex flex-row space-x-10">
            <NavLink
              to="/menus"
              className={({ isActive }) => (isActive ? " link-active" : "transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200")}
            >
              <button className="p-2 hover:bg-orange-500 text-white">
                MENUS
              </button>
            </NavLink>

            <NavLink
              to="/locations"
              className={({ isActive }) => (isActive ? " link-active" : "transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200")}
            >
              <button className="p-2  hover:bg-orange-500 text-white">
                LOCATIONS
              </button>
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? " link-active" : "transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200")}
            >
              <button className="p-2  hover:bg-orange-500 text-white">
                ABOUT
              </button>
            </NavLink>
          </div>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? " link-active" : "transition ease-in-out delay-50  hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-200")}
          >
            <button className="p-2  hover:bg-orange-500 text-white">
              CONTACT US
            </button>
          </NavLink>
        </div> */}

        {/* end buttons */}
      </div>
    </div>
  );
};
export default Navbar;
