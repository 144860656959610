import React from "react";
import sandwich from "../assets/sandwich.png";
import burger from "../assets/burger.png";
import pizza from "../assets/pizza.png";
import momos from "../assets/momos.png";
import { motion } from "framer-motion";

const Menu = () => {
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}

  >
    <div className="flex flex-col flex-wrap  p-2 mt-20 mb-12 items-center space-y-5">
      <div className="font-bold text-5xl text-black m-2">MENU</div>

      <div className="flex flex-col flex-wrap  font-bold text-xl font-sans mb-10 text-center">
        A culinary tour from around the eastern world and this <br />
        beautiful planet.
      </div>

      <div className="flex flex-row flex-wrap   w-full">
        <div className="flex flex-col md:w-1/2 md:pl-20 md:pr-20 ">
          <div className="flex flex-row items-center">
            <div className="outlinedtwo ml-7">SANDWICH</div>
            <img className="w-25 h-20 ml-auto m-2" src={sandwich} alt="sandwich"></img>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">BOMBAY MASALA SANDWICH</h1>
            <h1 className="font-bold text-sm ml-5">
              Sandwich clubbed with masala potatoes, onion, tomatoes, spicy
              chutney spread and ketchup.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">PANEER TIKKA SANDWICH</h1>
            <h1 className="font-bold text-sm ml-5">
              Cottage cheese marinated in indian style spicy tandoori sauce
              served in bread.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">VEG GRILLED SANDWICH</h1>
            <h1 className="font-bold text-sm ml-5">
              Finely chopped veggies drunk in mayo between the breads.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">CORN CHEESE SANDWICH </h1>
            <h1 className="font-bold text-sm ml-5">
              Sweet Corn drunk in cheese with veggies between the breads.
            </h1>
          </div>
        </div>
        <div className="flex flex-col md:w-1/2 md:pl-20 md:pr-20">
          <div className="flex flex-row  items-center">
            <div className="outlinedtwo ml-7">BURGER</div>
            <img className="w-25 h-20 ml-auto m-2" src={burger} alt="burger"></img>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">DESI ALOO TIKKI BURGER </h1>
            <h1 className="font-bold text-sm ml-5">
              Crispy aloo tikki doused with tomato ketchup and mayo topped with
              onion & tomatoes.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">VEG BURGER</h1>
            <h1 className="font-bold text-sm ml-5">
              Crispy, masaledaar veg patty doused with Spicy Hut's special
              sauce, topped with fresh onions and tomatoes.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">PANEER BURGER</h1>
            <h1 className="font-bold text-sm ml-5">
              Paneer doused with creamy sauce topped with fresh onion and
              tomatoes between buns.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">JUMBO VEG BURGER</h1>
            <h1 className="font-bold text-sm ml-5">
              Loaded with veg and paneer patty with spicy sauce and cheese
              topped with fresh onions and tomatoes.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">SUPER SPICY BURGER</h1>
            <h1 className="font-bold text-sm ml-5">
              Veg patty doused with extra spicy sauce and cheese topped with
              chilly seasoning onion and tomatoes.
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-row flex-wrap  w-full">
        <div className="flex flex-col flex-wrap  md:w-1/2  md:pl-20 md:pr-20 ">
          <div className="flex flex-row items-center">
            <div className="outlinedtwo ml-7">PIZZA</div>
            <img className="w-32 h-32 ml-auto m-2" src={pizza} alt="pizza"></img>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">CLASSIC MARGHERITA PIZZA</h1>
            <h1 className="font-bold text-sm ml-5">
              Classic delight with hundred percent mozzarella cheese.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">
              DOUBLE CHEESE MARGHERITA PIZZA
            </h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">SPICY PANEER PIZZA</h1>
            <h1 className="font-bold text-sm ml-5">
              Flavourful trio of paneer, capsicum with spicy red paprika.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">CHEESE & CORN PIZZA </h1>
            <h1 className="font-bold text-sm ml-5">
              A combination of sweet & juicy golden corn with cheese.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">ALL VEGGIE PIZZA </h1>
            <h1 className="font-bold text-sm ml-5">
              Delightful combination of onion, capsicum, tomato, red paprika &
              golden corn.
            </h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">ONION PIZZA </h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">TOMATO PIZZA </h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">PANEER & ONION PIZZA </h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">INDI TANDOORI PIZZA </h1>
            <h1 className="font-bold text-sm ml-5">
              It is hot, spicy, indian tandoori paneer with capsicum & red
              paprika.
            </h1>
          </div>
        </div>
        <div className="flex flex-col md:w-1/2 md:pl-20 md:pr-20">
          <div className="flex flex-row  items-center">
            <div className="outlinedtwo ml-7">MOMOS</div>
            <img className="w-36 ml-auto m-2" src={momos} alt="momos"></img>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">VEG STEAMED MOMOS </h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">PANEER STEAMED MOMOS</h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">VEG FRIED MOMOS</h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>

          <div className=" flex flex-col p-2 ">
            <h1 className="font-bold text-xl ml-5">PANEER FRIED MOMOS</h1>
            <h1 className="font-bold text-sm ml-5"></h1>
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col flex-wrap w-full md:p-10 space-y-5">
        <div className="flex flex-col flex-wrap md:w-1/3  md:pl-10 md:pr-10 ">
          <div className="outlinedtwo ml-7 mb-5">ROLLS</div>
          <h1 className="font-bold text-xl ml-7">VEG ROLL</h1>
          <h1 className="font-bold text-xl ml-7">PANEER ROLL</h1>
        </div>

        <div className="flex flex-col flex-wrap md:w-1/3  md:pl-10 md:pr-10 ">
          <div className="outlinedtwo ml-7 mb-5">MAGGIE</div>
          <h1 className="font-bold text-xl ml-7">SCHEZWAN MAGGI</h1>
          <h1 className="font-bold text-xl ml-7">NORMAL MAGGI</h1>
          <h1 className="font-bold text-xl ml-7">VEGGIE MAGGI</h1>
        </div>

        <div className="flex flex-col flex-wrap md:w-1/3  md:pl-10 md:pr-10 ">
          <div className="outlinedtwo ml-7 mb-5">CHAT</div>
          <h1 className="font-bold text-xl ml-7">ALOO TIKKI WITH DAHI</h1>
          <h1 className="font-bold text-xl ml-7">PANI PURI</h1>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default Menu;
