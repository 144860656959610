import "./App.css";
import Navbar from "./components/navbar.js";
import About from "./components/aboutus";
import Location from "./components/locations";
import Menu from "./pages/menus";
import Home from "./pages/home";
import Footer from "./components/footer.js"
import React from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router } from "react-router-dom";

import { Route, Routes} from "react-router-dom";

const App = () => {
  return (
    <>
      {/* header navbar */}

      <div className="">
        <Router>
        <Navbar />
          <AnimatePresence mode="wait" initial={true}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/location" element={<Location />} />
              <Route path="/menus" element={<Menu />} />
            </Routes>
          </AnimatePresence>
          <Footer/>
        </Router>
      </div>
    </>
  );
};

export default App;
